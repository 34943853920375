import React, { useEffect, useState } from "react";
import {  Tag, Spin,Modal ,Image,Popover, Col} from "antd";

import { HiFilm, HiFolder, HiHome, HiRefresh } from 'react-icons/hi';

import {  AiFillDelete, AiFillEdit, AiFillFolderOpen, AiFillSetting} from 'react-icons/ai';


import Cookies from "universal-cookie";
import { SideUserPlace } from "./SideUserPlace";
import moment from "moment";
import axios, { baseURLImg } from "../../../utils/API";
import { useHistory, useParams } from "react-router-dom";
import { RiHailFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";


const MangeFloorMyPlaceUser = () => {


       const FORMAT = "YYYY ddd MMM DD HH:mm";

       const cookies = new Cookies();

  const history = useHistory();

    const [IDuserads, setIDuserads] = useState(
    cookies.get("IDuserads")
  );

  const GoToMangeCompany = (e) => {
   // localStorage.setItem("IdAppProvince", "");
    history.push("UpdatePlace");
  };
  const Params = useParams();
  const [Flow, setFlow] = React.useState(0);


  
  const [dataProvince, setdataProvince] = useState([]);
  const [datauser, setdatauser] = useState([{username:""}]);

  const getdatauser = async () => {
    const resuser = await axios.get(`/users/all/user/dash/${Params.iduser}`)

    setdatauser(resuser.data.data);
  }
  const getallsupuser = async () => {
    
    const res = await axios.get(`/folder/all/folderandfiles/user/dash/${Params.iduser}/${Params.flow}`)
       setdataProvince(res.data.data);
     
      
     }



  useEffect(() => {
    try {
     
      setFlow(Params.flow)
      getallsupuser();
      getdatauser();
    } catch (error) {
      
    }
    
  },[Params.flow,Params.iduser]);

  const showfilefolder=async(idfolder)=>{
    //history.location(`MangeFloorMyPlaceUser/${IDuserads}/${idfolder}`,{"flow":idfolder})
    const res = await axios.get(`/folder/all/folderandfiles/folder/${idfolder}`)
    setdataProvince(res.data.data);
   
    history.push(`/ManagFilesUser/${Params.iduser}/${idfolder}`);

  }
//console.log(datauser)


  const UpdateData =async () => {
    getallsupuser();
    getdatauser();
  };


 


    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    const [NameFile, setNameFile] = React.useState('');
    const [IdFile, setIdFile] = React.useState('');
    const [TypeFile, setTypeFile] = React.useState('');
    const [Color, setColor] = React.useState('');

    
    //addfolder
    const [NameFolder, setNameFolder] = React.useState('');

    const [visiblefolder, setVisiblefolder] = React.useState(false);
    const [confirmLoadingfolder, setConfirmLoadingfolder] = React.useState(false);

   
    const showModalfolder = () => {
      setVisiblefolder(true);
    };

  

    const handleOkfolder =async () => {
     
      setConfirmLoadingfolder(true);
      
 
      console.log(Flow)
   await  axios.post("folder/",{"NameFolder": NameFolder,
   "Flow": Params.flow,"UserID":datauser[0].iduser,"Color": "#F7971E"});
      setTimeout(() => {
        setVisiblefolder(false);
        setConfirmLoadingfolder(false);
        setNameFolder("")
      }, 2000);
      getallsupuser();
      getdatauser();
    };
  
    const handleCancelfolder = () => {
      console.log('Clicked cancel button');
      setVisiblefolder(false);
      getallsupuser();
      getdatauser();
    };



    //end folder


     //addfoldercolor

     const [visiblefoldercolor, setVisiblefoldercolor] = React.useState(false);
     const [confirmLoadingfoldercolor, setConfirmLoadingfoldercolor] = React.useState(false);
 
    
     const showModalfoldercolor = () => {
       setVisiblefoldercolor(true);
     };
 
   
 
     const handleOkfoldercolor =async () => {
      
       setConfirmLoadingfoldercolor(true);
      
       axios.put("/folder/color",{"IdFolder":IdFile,"Color":Color})
       
    
       setTimeout(() => {
         setVisiblefoldercolor(false);
         setConfirmLoadingfoldercolor(false);
         setColor("")
       }, 2000);
       getallsupuser();
       getdatauser();
     };
   
     const handleCancelfoldercolor = () => {
       console.log('Clicked cancel button');
       setVisiblefoldercolor(false);
       getallsupuser();
       getdatauser();
     };
 
 
 
     //end foldercolor

    const showModal = () => {
      setVisible(true);
    };
  
    const handleOk =async () => {
      
      setConfirmLoading(true);
      
      
  await  axios.put(TypeFile==="Folder"?"/folder/rename/":"/folder/files/rename/",TypeFile==="Folder"?{"NameFolder": NameFile,
   "IdFolder": IdFile}:{"NameFile": NameFile,
   "IdFile": IdFile});
     
      setTimeout(() => {
        setVisible(false);
        setConfirmLoading(false);
      }, 2000);
      getallsupuser();
      getdatauser();
    };
  
    const handleCancel = () => {
      console.log('Clicked cancel button');
      setVisible(false);
      setVisiblefolder(false);

    };

    const colors=[
      {color:"#cf1322",id:1},
      {color:"#fa8c16",id:2},
      {color:"#fadb14",id:3},
      {color:"#a0d911",id:4},
      {color:"#096dd9",id:5},
      {color:"#08979c",id:6},
      {color:"#eb2f96",id:7},
      {color:"#531dab",id:8},
      {color:"#2f54eb",id:9},
      {color:"#120338",id:10},
      {color:"#f5222d",id:11},
      {color:"#e6f7ff",id:12},


    ]
    
    const [imagedata,setimagedata]=useState([]);
    const [imagedata2,setimagedata2]=useState({files:[]});

    const handleChangeImage = (event) => {
     imagedata2.files.length===0?setimagedata2({ files: [ ...event.target.files] }):setimagedata2({ files: [ ...imagedata2.files,...event.target.files] })
      //setdataProvince([ ...dataProvince,{ ...event.target.files}] )
      
       /* FlowFolder: "0"
IdFile: "e7a935fb-69f0-4ec5-875f-01308412fa7d"
Image: event.target.files.type
NameFile: " 222"
Path: "1637835398521-Osamah Resume.pdf"
Type: "File"*/
      
    }

    console.log(dataProvince)
  return (
   <div style={{ display: "flex", width: "100%" }} >
        <SideUserPlace />
    <div  className="widiv"  style={{ display: "flex",flexDirection:"column" }}> 


      {dataProvince.length < 0 && (
        <Spin
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            background: "#ffffffc1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 75454,
          }}
          tip="Loading..."
        ></Spin>
      )}
      <div
        className="dashboard_main"
        style={{ justifyContent: "space-between",background:"rgb(229, 233, 234)",margin:5,padding:"0px 5px" }}
      >
       

        <div 
          
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",direction:"rtl",cursor:"none"
            }}
          >
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center",cursor:"default",flexWrap:"wrap" }}
              className="Tagclass"
              color="default"
              
            >
     

             <p style={{margin:0}}>{" المستخدم"+" : " +datauser[0].username + " " + " / " }</p>
             <p style={{margin:0}} >{"الهاتف"+" : " +datauser[0].phone + " " + " / " }</p>
             <p style={{margin:0}}>{"المشروع"+" : " +datauser[0].asemmshro + " " + " / " }</p>
             <p style={{margin:0}}>{"الاضبارة"+" : " +datauser[0].rqmadbara  }</p>
            </Tag>
          </div>

        <div
          style={{ padding: 5, display: "flex", justifyContent: "flex-start" }} className="acton"
        >

<div 
            onClick={()=>history.push("/Updatausermyplace")}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
             <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="volcano"
            >
             
              الرئيسية
               <HiHome size={20} />
            </Tag> 
         
           

         

  

          </div>
          <div 
            onClick={showModalfolder}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="blue"
            >
             
              اضافة مجلد
               <HiFolder size={20} />
            </Tag>
           

         

  

          </div>
          
          <div 
            onClick={()=>history.push(`/AddPost/${Params.iduser}/${Params.flow}/`)}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="geekblue"
            >
             
              
             اضافة ملفات

              
    <RiHailFill size={20} style={{margin:"0 5px 0 0"}}/>

            </Tag>
          </div>
          <div 
            onClick={UpdateData}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="orange"
            >
             
              تحديث البيانات
               <HiRefresh size={20} />
            </Tag>
          </div>

          <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag  className="Tagclass" style={{ padding: 10, fontWeight: 700 }} color="green">
              العدد الكلي : {dataProvince.length}
            </Tag>
          </div>

 <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >

         
           
          
           </div>
          

        

        </div>

         
        
      </div>

    
     
      <Modal
        title="اضافة مجلد" 
        visible={visiblefolder}
        onOk={handleOkfolder}
        confirmLoading={confirmLoadingfolder}
        onCancel={handleCancelfolder}
        okText="اضافة"
        cancelText="الغاء"
        
      >
        <input placeholder="يرجى كتابة اسم المجلد هنا" value={NameFolder} onChange={e=>setNameFolder(e.target.value)} className="inputrename"/>
      </Modal>
                
    
      <Modal
        title="تغير لون المجلد" 
        visible={visiblefoldercolor}
        onOk={handleOkfoldercolor}
        confirmLoading={confirmLoadingfoldercolor}
        onCancel={handleCancelfoldercolor}
        okText="تغير لون المجلد"
        cancelText="الغاء"
        
      >
        <div className="colorcontainer">
          {colors.map((res,i)=>
                    <div key={i} className="color" style={{background:res.color}} onClick={()=>{
                     
                      setColor(res.color)
                     
                     
                    }
                    }></div>

          )}
        </div>
             </Modal>
      
       <div style={{ width: "100%",padding:"10px 15px",overflow:"auto",display:"flex",flexWrap:"wrap" }}>
      

       
          {dataProvince.map((res,i)=>
           <div key={i} className="folder" style={{background:res.Type==="Folder"&&res.Color}}>
             { res.Type==="Folder" && <h4 className="namefoldercenter">{res.NameFolder} </h4>}
             
           {/*<h5 className="typefile">{res.Type}</h5>*/}
           {res.Image==="image/jpeg" ? <div style={{position:"absolute",zIndex:1,width:"100%",height:"100%",border:"2px solid #403b3b"}}> <Image src={baseURLImg+res.Path} alt={res.Path} width="100%" height="100%"  style={{position:"absolute",zIndex:1}} /></div> :res.Image==="application/pdf" &&<div style={{position:"absolute",zIndex:1,width:"100%",height:"100%",border:"2px solid #403b3b"}}> <img src={window.location.origin + "/img/pdf.jpg"} alt={res.Path} width="100%" height="100%" style={{position:"absolute",zIndex:1}} /> </div>}
             
             <div className="divcontiner">
             <h5 className="namefile">{res.NameFile}</h5>
             <div>
             <h5 className="typefileopen">
              <AiFillFolderOpen size={15} onClick={()=>{
            res.Type==="Folder"?showfilefolder(res.IdFolder):window.open(baseURLImg+res.Path, "_blank") 
           }}/>
           
            </h5>
            <Popover  zIndex={11} placement="bottom" title={"الاعدادات"} content={
               <div>
               <div className="menuwbtn" onClick={()=>{
                       res.Type==="Folder"?showfilefolder(res.IdFolder):window.open(baseURLImg+res.Path, "_blank") 
                      }}>
              
               <AiFillFolderOpen style={{marginLeft:5}}  size={20} />
                       <h4 style={{margin:0}}>مشاهدة</h4>
               
               </div>

               <div className="menuwbtn" onClick={()=>{
                       res.Type==="Folder"?axios.delete("/folder/",{data:{"IdFolder":res.IdFolder}}) :axios.delete("/folder/files/delete/",{data:{"IdFile":res.IdFile}}) 
                      getallsupuser();
                      }}>
              
              <AiFillDelete style={{marginLeft:5}}  size={20} />
                      <h4 style={{margin:0}}>حذف</h4>
              
              </div>

              {res.Type==="Folder"&&
              <div className="menuwbtn" onClick={()=>{
                setIdFile(res.Type==="Folder"?res.IdFolder:res.IdFile)
                showModalfoldercolor()
                }}>
              
              <AiFillDelete style={{marginLeft:5}}  size={20} />
                      <h4 style={{margin:0}}>تغير لون المجلد</h4>
              
              </div>
            }
              <div  >
               
                      
                      <div className="menuwbtn"  onClick={()=>{
                          showModal()
                          setTypeFile(res.Type)
                          setIdFile(res.Type==="Folder"?res.IdFolder:res.IdFile)
                          setNameFile(res.Type==="Folder"?res.NameFolder:res.NameFile)
                      }
                       
                       }>
                      <AiFillEdit style={{marginLeft:5}}  size={20} />
                      <h4 style={{margin:0}}>تغير الاسم</h4>
      </div>
      <Modal
        title={  "تغير اسم  " + " "+`${res.Type==="Folder"?"المجلد":"الملف"}`+  ":" + `${res.Type==="Folder"?res.NameFolder:res.NameFile}`}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText="تغير"
        cancelText="الغاء"
        
      >
        <input value={NameFile} onChange={e=>setNameFile(e.target.value)} className="inputrename"/>
      </Modal>
              </div>

             
             </div>
            } trigger="click">
     
            <h5 style={{left:10,backgroundColor:"#f44339"}} className="typefileopen">
              <AiFillSetting size={15} />
           
            </h5>
            </Popover>
             </div>    
</div>
       </div>
   
          )}

         

        </div>
     </div>
    </div>
  );
};

export default MangeFloorMyPlaceUser;
