import { Col, Row, Image, message, Tag,Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import axios, { baseURLImg, IdAppCompany } from "../../../utils/API.js";
import { Rooms,Category ,StateContry} from "../../../agent";
import { useHistory } from "react-router-dom";
import { BiMessageAltAdd } from 'react-icons/bi';
import { RiArrowGoBackFill, RiDeleteBackFill, RiDeleteBin5Fill} from 'react-icons/ri';
import { AiTwotoneSave} from 'react-icons/ai';
import Cookies from "universal-cookie/es6";
import { SideUserPlace } from "./SideUserPlace.js";
var numbro = require("numbro");


const AddNewRoommyplace = () => {
        const cookies = new Cookies();

const histiry = useHistory();
    const [IdUser, setIdUser] = useState(cookies.get("userid"));
const [StateCompany, setStateCompany] = useState([]);
  const GoToMangePlaces = (e) => {
    histiry.push("MangeAllRoom");
  };
 
const [Category, setCategory] = useState([]);

    useEffect(() => {
        const Categoryaasync = async () => {
             const res = await axios.get("/category");
        setCategory(res.data.data)
        }

     
      
        Categoryaasync();
       
    }, [])

  const [IdAppPlace, setIdAppPlace] = useState(
    cookies.get("IdAppPlace")
  );

  const [Places_Image, setPlaces_Image] = useState({ file: "" });

  const [Places_Imageupload, setPlaces_Imageupload] = useState({
    file: window.location.origin + "/img/Noimage.jpg",
  });

  const [Rooms_Name_ar, setRooms_Name_ar] = useState("عروض مميزة ");
  const [Rooms_Name_en, setRooms_Name_en] = useState("");
  const [Rooms_Name_ku, setRooms_Name_ku] = useState("");
  const [Rooms_bedtype_ar, setRooms_bedtype_ar] = useState("0");
  const [Rooms_bedtype_en, setRooms_bedtype_en] = useState("");
  const [Rooms_bedtype_ku, setRooms_bedtype_ku] = useState("");
  const [Rooms_Space, setRooms_Space] = useState("0");
  const [Rooms_Space1, setRooms_Space1] = useState("14");
  const [Rooms_Services_ar, setRooms_Services_ar] = useState("");
  const [Rooms_Services_en, setRooms_Services_en] = useState("");
  const [Rooms_Services_ku, setRooms_Services_ku] = useState("");
  const [Rooms_priceAdult, setRooms_priceAdult] = useState("");
  const [Rooms_priceChild, setRooms_priceChild] = useState("");
  const [Price_Currency, setPrice_Currency] = useState("USD");


  const handleChangeImage = (event) => {
    setPlaces_Imageupload({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image({
      file: event.target.files[0],
    });
  };
const [disable, setDisable] = useState(false);

  const UpdateDetailsPlace = async (e) => {

    try {
      
      message.loading("loading")
    e.preventDefault();
    setDisable(true)
      
      const res =await axios.post("/supscriptadsuser", {
        name: Rooms_Name_ar,
        contain: Rooms_Services_ar,
        price: Rooms_bedtype_ar,
        curncy: Price_Currency,
        category: Rooms_Name_en,
        offernumber: Rooms_Space,
        day: Rooms_Space1,
        categoryEn:Rooms_Name_ku
      })

      if (res.status === 200) {
        
        
        setTimeout(() => {
          message.success("تم حفظ الاشتراك بنجاح");
          setDisable(false)
              setTimeout(() => {
        histiry.push("MangeAllRoom");
           
        }, 500);
        }, 500);

      
        
      }
      else {
         message.error("لم يتم حفظ الاشتراك يرجى المحاولة مرة اخرى");
       setDisable(false)
      }
    
         } catch (error) {
      console.log(error)
       setDisable(false)
    }
  };

  return (
    <div style={{ display: "flex", width: "100%" }} >
   <SideUserPlace />
      <form className="widiv" style={{ display: "flex",flexDirection:"column" }} onSubmit={(e) => UpdateDetailsPlace(e)}>

        <div className="dashboard_main" style={{ justifyContent: "center" }}>
          <Row>

               <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",justifyContent:"space-between",background:"rgb(229 233 234)"
              }}
              xs={24}
              md={24}
              lg={24}
              xl={24}
            >
             <button disabled={disable}   style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }} className="btnsavecompany" type="submit">
                حفظ
                <AiTwotoneSave size={20} />
              </button>

              <div style={{display:"flex",justifyContent:"space-around",alignItems:"center"}}>

             
              
              
              
                 <Tag
               onClick={GoToMangePlaces}
               style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="green"
            >
                  العودة الى اعددات الاشتراكات
                   <RiArrowGoBackFill size={20} />
                </Tag>
                 </div>
                      </Col>
                      
            <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              xs={24}
              md={24}
              lg={18}
              xl={20}
            >
              <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>اسم الاشتراك عربي</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={Rooms_Name_ar}
                    onChange={(e) => setRooms_Name_ar(e.target.value)}
                    required
                   
                  />
                </div>
              </Col>

              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>فئة الاشتراك عربي</label>
                  </div>

  <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                     value={Rooms_Name_en}
                    onChange={(e) => setRooms_Name_en(e.target.value)}
                    required
                  
                  >
                     <option></option>
                    {Category.map((res, i) =>
                      <option key={i}>{res.name_ar}</option>
                    )}
                   
                   
                  </select>
                
                </div>
              </Col>

              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>فئة الاشتراك انكليزي</label>
                  </div>

                    <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                      value={Rooms_Name_ku}
                    onChange={(e) => setRooms_Name_ku(e.target.value)}
                    required
                 
                  >
                    <option></option>
                   {Category.map((res, i) =>
                      <option key={i}>{res.name_en}</option>
                    )}
                  </select>

                </div>
              </Col>

            

              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={24}
                lg={24}
                xl={24}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>مميزات الاشتراك</label>
                    
                  </div>

                    
   <textarea
                    style={{ height: 70 }}
                    className="inputtext"
                    value={Rooms_Services_ar}
                    onChange={(e) => setRooms_Services_ar(e.target.value)}
                    required
                   
                  />
                  
                  
                </div>
              </Col>

             
<Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={6}
                lg={6}
                xl={6}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الخصم % </label>
                  </div>

                  <input
                    className="inputtext"
                    type="number"
                    value={Rooms_Space}
                    onChange={(e) => setRooms_Space(e.target.value)}
                    required
                  
                  />
                </div>
              </Col>
              <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={6}
                lg={6}
                xl={6}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>عدد الوحدات</label>
                  </div>

                   <input
                    className="inputtext"
                    type="number"
                    value={Rooms_Space1}
                    onChange={(e) => setRooms_Space1(e.target.value)}
                    required
                  
                  />
                </div>
              </Col>

                      <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={6}
                lg={6}
                xl={6}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>السعر</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={Rooms_bedtype_ar}
                    
                  onChange={(e) => {
                      try {
                        if (e === "")
                          setRooms_bedtype_ar(
                            numbro(0).format({ thousandSeparated: true })
                          );
                        else {
                          setRooms_bedtype_ar(
                            numbro(e.target.value).format({ thousandSeparated: true })
                          );
                        }
                      } catch (error) {}
                    }}
                    required
                  />
                </div>
              </Col>    
                         
             

             

              <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={6}
                lg={6}
                xl={6}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>العملة</label>
                  </div>


                   <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                    value={Price_Currency}
                    onChange={(e) => setPrice_Currency(e.target.value)}
                    required
                   
                                  >
                    <option>USD</option>
                    <option>IQD</option>
                  </select>
                </div>
              </Col>

            

             
            </Col>

         
           
          </Row>
        </div>
      </form>
    </div>
  );
};

export default AddNewRoommyplace;
