import { Col, Row, Image, message, Tag ,Modal,Checkbox} from "antd";
import React, { useEffect, useState } from "react";
import axios, { baseURLImg, IdAppCompany } from "../../../utils/API.js";
import {Category,Users } from "../../../agent";
import { useHistory, useParams } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';


import { RiChatDeleteFill ,RiArrowGoBackFill, RiImage2Fill} from 'react-icons/ri';
import { AiTwotoneSave} from 'react-icons/ai';
import Cookies from "universal-cookie";
import { SideUserPlace } from "./SideUserPlace.js";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { HiRefresh } from "react-icons/hi";


const AddPost = () => {
       const FORMAT = "YYYY ddd MMM DD HH:mm";
var numbro = require("numbro");

         const cookies = new Cookies();

    const CatPlace = cookies.get("usercat");

  const [IdUser, setIdUser] = useState(cookies.get("userid"));
  const [iduserupdate, setiduserupdate] = useState(cookies.get("IdAds"));
  
    const [IdAppPlace, setIdAppPlace] = useState(
    cookies.get("IdAppPlace")
  );

  function destroyAll() {
  Modal.destroyAll();
}

const [Category, setCategory] = useState([]);

const [datauser, setdatauser] = useState([{username:""}]);
const [IDuserads, setIDuserads] = useState(
  cookies.get("IDuserads")
);
  const getdatauser = async () => {
    const resuser = await axios.get(`/users/all/user/dash/${Parms.iduser}`)

    setdatauser(resuser.data.data);
  }

  const Parms = useParams();
    useEffect(() => {
       
        getdatauser()
     
      
    
       
    }, [Parms.iduser,Parms.flow])
  
  const { confirm } = Modal;
  
  function showConfirm() {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "هل انت متأكد من حذف هذا الاعلان ؟ ",
        okText:"نعم",cancelText:"لا",
        onOk() {
        axios.delete('/ads/', {data:{"IdAds":iduserupdate}});
          message.success("Delete");
       
          setTimeout(() => {
          history.push("MangeFloorMyPlace");

          }, 500);
        },
        onCancel() {
         
        },
      });
    },500);
  
  }
  
const history = useHistory();

  
    const [CategoryCompany, setCategoryCompany] = useState([]);
    const [StateCompany, setStateCompany] = useState([]);

    const GoToMangePlaces = (e) => {
    history.push("MangeFloorMyPlace");
  };

  



  const [Places_Image, setPlaces_Image] = useState({ file: "" });

  const [PhotoUser, setPhotoUser] = useState({
   file: window.location.origin + "/img/Noimage.jpg",
  });



  const [NameUser, setNameUser] = useState("");
  const [NameAds, setNameAds] = useState("");
  const [CategoryAr, setCategoryAr] = useState("");
   const [CategoryEn, setCategoryEn] = useState("");
  const [isSpecial, setisSpecial] = useState("");
  const [isNewOffer, setisNewOffer] = useState("");
  
  
  const [AdressAds, setAdressAds] = useState("");
  const [PriceAds, setPriceAds] = useState("");
  const [Curency, setCurency] = useState("");
  const [ImagsAds, setImagsAds] = useState([]);
  const [Descreption, setDescreption] = useState("");
  const [UseriIDDetalis, setUseriIDDetalis] = useState("");

  
  const [DateAds, setDateAds] = useState("User");
  const [Phone, setPhone] = useState("");
  const [TypeUser, setTypeUser] = useState("");
  const [state, setstate] = useState("Active");
  
  const [Places_Imageupload, setPlaces_Imageupload] = useState({
    file: window.location.origin + "/img/Noimage.jpg",
  });
  const UpdateDetailsPlaceAndUpload = (file) => {
    const url = "/ads/add/imgads";
    const formData = new FormData();
    formData.append("document", Places_Image.file);
    formData.append("IdAds", iduserupdate);
       
   
    return axios.post(url, formData);
  };

 
const UpdateState = (e) => {

    history.push("Updatausermyplace");
    cookies.set("iduserupdate",UseriIDDetalis)
  };

/*
     useEffect(() => {
    const GetDataPlaces = async (IdAppPlace) => {
      const dataPlace = await axios.get(`/ads/oneads/${iduserupdate}`)
setImagsAds(dataPlace.data.data[0].imageads);

     setNameUser(dataPlace.data.data[0].users[0].firstname + " " + dataPlace.data.data[0].users[0].lastname);
      setNameAds(dataPlace.data.data[0].NameAds);
      setCategoryAr(dataPlace.data.data[0].CategoryAds);
            setDescreption(dataPlace.data.data[0].Descreption);
setUseriIDDetalis(dataPlace.data.data[0].UserID)
      setCategoryEn(dataPlace.data.data[0].CategoryAdsEn);
      
       setisSpecial(dataPlace.data.data[0].isSpecial);
        setisNewOffer(dataPlace.data.data[0].isNewOffer);


      setAdressAds(dataPlace.data.data[0].address);
      setPriceAds(dataPlace.data.data[0].PriceAds);
      setCurency(dataPlace.data.data[0].CurncyAds);
      setDateAds(dataPlace.data.data[0].createdAt);

      setPhone(dataPlace.data.data[0].phone);

      setstate(dataPlace.data.data[0].State);

      /*
      setPlaces_Imageupload({
        file: `${baseURLImg}${dataPlace.data.data[0].ImageUser}`,
      });
      setPhotoUser({
        file: `${baseURLImg}${dataPlace.data.data[0].ImageUser}`,
      });


    };

    GetDataPlaces(iduserupdate);
     }, [iduserupdate]);
  */

     const [imagedata,setimagedata]=useState([]);
     const [imagedata2,setimagedata2]=useState({files:[]});

  const handleChangeImage = (event) => {
    imagedata2.files.length===0?setimagedata2({ files: [ ...event.target.files] }):setimagedata2({ files: [ ...imagedata2.files,...event.target.files] })

  }
 
  const UpdateDetailsPlace = async (e) => {
    try {
     
    e.preventDefault();
 
   if(imagedata2.files.length===0)
   {
    
         
          message.info("يرجى اختيار ملفات اولاً");
        
   
   }
    
    else{

      message.loading("loading")
      const formData = new FormData();
      for (const key of Object.keys(imagedata2.files)) {
        formData.append("document", imagedata2.files[key]);
        formData.append("NameFile", imagedata2.files[key].name);
       formData.append("Image", imagedata2.files[key].type);

      }
      formData.append("FlowFolder", Parms.flow);

      formData.append("UserID",Parms.iduser);
      
  console.log(formData)
        const res =await axios.post("/folder/dash/files/save/",formData)
    
    
     
  
     

      if(res.status===200)
      {

        setTimeout(async () => {
      
         
         
          message.success("تم رفع الملفات بنجاح");
          history.push(`/ManagFilesUser/${Parms.iduser}/${Parms.flow}`);
        }, 500)
      }
      else {
        
         message.error("لم يتم رفع الملفات ");
        
  }

}
          
    
    } catch (error) {
      console.log(error)
    }
    
  };


   
  
  //password reset

  const [resetpsw, setresetpsw] = useState(false)
  const [resetpswText, setresetpswText] = useState("Password Reset")
  const RestPsw =async () => {
    if (resetpsw==false) {
      setresetpsw(true);
    setAdressAds("");
    setresetpswText('Cancel')
    }
    else {
      setresetpsw(false);
      const dataPlace = await Users.GetOneUserByIdUser(IdUser);
      setAdressAds(dataPlace.data.data[0].AdressAds);
      setresetpswText('Password Reset')
    }
  

  }


  

   //Model Add State


 
   
const [ImagesPlace_ID, setImagesPlace_ID] = useState("")

  const UpdateDetailsPlaceAndUploadiMAGE = (file) => {
    const url = `/slidertop`;
    const formData = new FormData();
    formData.append("document", Places_Image.file);
    return axios.post(url, formData);
  };


  
  const UpdateDetailsPlaceiMAGE = async (e) => {
    try {
      if (Places_Imageupload.file === "") {
      message.error("error");
    } else {
      //e.preventDefault();

      UpdateDetailsPlaceAndUpload(Places_Imageupload.file)
      

        setConfirmLoadingState(true);
    setTimeout(async () => {
       
         
      const dataPlace = await axios.get(`/ads/oneads/${iduserupdate}`)
      setImagsAds(dataPlace.data.data[0].imageads);

     setVisibleState(false);
      setConfirmLoadingState(false);
      message.success("Add");
    }, 500);
          
    }
    } catch (error) {
      console.log(error)
    }
    
  };
  const [visibleState, setVisibleState] = useState(false);
   const [confirmLoadingState, setConfirmLoadingState] = useState(false);  
   
  const showModalState = () => {
      setVisibleState(true);
      setPlaces_Imageupload({ file: window.location.origin + "/img/Noimage.jpg" })
  };

  const handleCancelState = () => {
    setVisibleState(false);
  };


  
  async function showConfirmDeleteImag(e) {
    const imgdelete = imagedata2.files.filter(res=>res.name!==e);
      
    setimagedata2({ files: [ ...imgdelete] })
    
  /*
    setTimeout(async() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "هل انت متأكد من حذف هذه الصورة  ؟ ",
        okText:"نعم",cancelText:"لا",
      async  onOk() {
      
       //setimagedata2(imgdelete)

      
          axios.delete(`/ads/delete/imgads`, {
            data: {
              "_id":e
            }
            
          }
          
          );
         
          
          setTimeout(async() => {
               
            const dataPlace = await axios.get(`/ads/oneads/${iduserupdate}`)
      setImagsAds(dataPlace.data.data[0].imageads);
            
          }, 1000);
    

      // window.location.reload(false);

            
             
           
        },
        onCancel() {
         
        },
         
      });
       
    },1000);
  
       */
   
  }


  return (
        <div style={{ display: "flex", width: "100%" }}>
                    <SideUserPlace />
      <div  className="widiv"  style={{ display: "flex",flexDirection:"column" }}>
      <form onSubmit={(e) => UpdateDetailsPlace(e)}>
        
        <div className="dashboard_main" style={{ justifyContent: "center"}}>
          <Row style={{width: "100%"}}>

              <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",justifyContent:"space-between",background:"rgb(229 233 234)",
                width:"100%"
              }}
              xs={24}
              md={24}
              lg={24}
              xl={24}
            >
              
              <div 
          
          style={{
            padding: 5,
            display: "flex",
            justifyContent: "flex-start",direction:"rtl",cursor:"none"
          }}
        >
          <Tag
            style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center",cursor:"default",flexWrap:"wrap" }}
            className="Tagclass"
            color="default"
            
          >
   

           <p style={{margin:0}}>{" المستخدم"+" : " +datauser[0].username + " " + " / " }</p>
           <p style={{margin:0}} >{"الهاتف"+" : " +datauser[0].phone + " " + " / " }</p>
           <p style={{margin:0}}>{"المشروع"+" : " +datauser[0].asemmshro + " " + " / " }</p>
           <p style={{margin:0}}>{"الاضبارة"+" : " +datauser[0].rqmadbara  }</p>
          </Tag>
        </div>

              <button   style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }} className="btnsavecompany" type="submit">
                حفظ
                <AiTwotoneSave size={20} />
              </button>
              <Tag
             
             style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}

className="Tagclass"
color="green"
>

 
  <label  htmlFor="fileup2">
 اضافة ملفات

</label>
    <RiImage2Fill size={20} style={{margin:"0 5px 0 0"}}/>

    <input
  type="file"
  onChange={(e) => handleChangeImage(e)}
  multiple
  style={{ background: "#ffeb3b", border: "1px solid black" }}
  id="fileup2"
  hidden
  accept="image/*,application/pdf"

/>

  </Tag>

             
            <Tag
            onClick={()=>history.push(`/ManagFilesUser/${Parms.iduser}/${Parms.flow}`)}
              className="Tagclass"
              color="orange"
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
            >
             
             الرجوع الى الملفات
               <HiRefresh size={20} />
            </Tag>
        
              

              <div className="acton"  style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",justifyContent:"space-between"
              }}>
               
              
                  
                  
              
                </div>
            </Col>
            <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              xs={24}
              md={24}
              lg={24}
              xl={24}
            >
             


               
                <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
              xs={24}
              md={24}
              lg={24}
              xl={24}
                >
                


                  
                </Col>

                <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
              }}
              xs={24}
              md={24}
              lg={24}
              xl={24}
                >
                


                  
                </Col>

               
 {imagedata2.files.map((res,i)=>
                          <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",maxHeight:320,height:320
              }}
              xs={12}
              md={12}
              lg={4}
              xl={4}
                >
                 
              <div className="containerimag" key={i}>

                  <div className="lableinput">
         <span className="lableinput">{res.name}</span>
                  <MdDelete className="restbtn"   onClick={()=>showConfirmDeleteImag(res.name)} size={30} />

    
       </div>
       
          
                <Image  style={{maxHeight:"200px",minHeight:"200px"}} width={"100%"} height={"100%"} src={res.type==="application/pdf"?window.location.origin + "/img/pdf.jpg":URL.createObjectURL(res)} />
                         
                  </div>
                  
                </Col>
                )}
                          
</Col>
          
         
          
          </Row>
        </div>
      </form>
      </div>
       </div>
  );
};

export default AddPost;
