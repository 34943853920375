import { message, Tag } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineSend, AiTwotoneSave } from 'react-icons/ai';
import { useParams } from 'react-router-dom';
import Cookies from "universal-cookie";
import axios, { baseURLImg } from "../../../utils/API";
import { SideUserPlace } from './SideUserPlace';

const MeassageUser = () => {
    const cookies = new Cookies();

    const Params = useParams();
  const [Flow, setFlow] = React.useState(0);

  const FORMAT = "YYYY ddd MMM DD HH:mm";

  
  const [MsgAdmin, setMsgAdmin] = useState("");

  const [dataProvince, setdataProvince] = useState([]);
  const [datauser, setdatauser] = useState([{username:""}]);

  const getdatauser = async () => {
    const resuser = await axios.get(`/users/all/user/dash/${Params.iduser}`)

    setdatauser(resuser.data.data);
  }
  const getallsupuser = async () => {
    
    const res = await axios.get(`/msg/all/user/${Params.flow}`)
       setdataProvince(res.data.data);
     
      
     }

     useEffect(() => {
        try {
         
       
          getallsupuser();
          
        } catch (error) {
          
        }
        
      });
    

  useEffect(() => {
    try {
     
      setFlow(Params.flow)
      getallsupuser();
      getdatauser();
    } catch (error) {
      
    }
    
  },[Params.flow,Params.iduser]);

  const UpdateData =async () => {
    getallsupuser();
    getdatauser();
  };

  const myRef = useRef(null)
  const executeScroll = () => myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })  ;  

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)   

  const sendmsg =async(e)=>{
   
    if(MsgAdmin==="")
    {
     message.error("يرجى كتابة الرسالة")
    }
    else{
        await axios.post("/msg",{
            "UserID":cookies.get("iduser"),
            "Nameuser":cookies.get("NameUser"),
            "Msg":MsgAdmin,
            "Sender":"admin",
            "RoomMsg":Params.flow
        })
        UpdateData();
        setMsgAdmin("");
        executeScroll()
    }

  }

  
useEffect(() => {
  //executeScroll();
  setTimeout(() => {
    scrollToRef(myRef);
    executeScroll()
  }, 500);
},[])
    return (
        <div style={{ display: "flex", width: "100%" }} >

<SideUserPlace />

        <div className='containermsguser'>
          
           <div 
          className='headeruser'
          style={{
            padding: 5,
            display: "flex",
            justifyContent: "flex-start",direction:"rtl",cursor:"none"
          }}
        >
          <Tag
            style={{backgroundColor:"#4a4197",color:"#fff",border:0, padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center",cursor:"default",flexWrap:"wrap" }}
            className="Tagclass"
            color="default"
            
          >
   

           <p style={{margin:0}}>{" المستخدم"+" : " +datauser[0].username + " " + " / " }</p>
           <p style={{margin:0}} >{"الهاتف"+" : " +datauser[0].phone + " " + " / " }</p>
           <p style={{margin:0}}>{"المشروع"+" : " +datauser[0].asemmshro + " " + " / " }</p>
           <p style={{margin:0}}>{"الاضبارة"+" : " +datauser[0].rqmadbara  }</p>
          </Tag>
        </div>
           <div  className='bodymsguser'>
               {dataProvince.map((res,i)=>
               <div ref={myRef} key={i} className={res.Sender!=="admin"?'boxmsguser adminsend':'boxmsguser'} >
<Tag
                style={{marginTop:10,height:40, backgroundColor:"rgb(114 112 127)",color:"#fff",border:0, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center",cursor:"default",flexWrap:"wrap" }}
                className="Tagclasss"
                color="default"
                
              >
                  {res.users[0].username}
              </Tag> 
              <div style={{minWidth:160}}>

                <Tag
                style={{borderRadius:res.Sender!=="admin"?"0px 5px 10px 0px":"5px 0px 0px 10px",marginTop:10, backgroundColor:res.Sender!=="admin"?"rgb(215 100 84)":"#4a4197",color:"#fff",border:0, padding:"10px 20px", fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center",cursor:"default",flexWrap:"wrap" }}
                className="Tagclasss"
                color="default"
                
              >
                  {res.Msg}
                 
              </Tag>
              <p>{moment(res.createdAt).format(FORMAT)}</p>
              </div>
              </div>
               )}
          
           </div>
           <div className='footermsg'>
          
                
                  <textarea
                   style={{ height: 50,fontSize:13 }}
                    className="inputtext"
                    placeholder='يرجى كتابة الرسالة هنا'
                    value={MsgAdmin}
                    onChange={(e) => setMsgAdmin(e.target.value)}
                    required
                  />
    <button onClick={()=>sendmsg()}   className="brnsend" >
               
                <AiOutlineSend size={20} style={{ margin: "-8px 5px"}}/>
                ارسال
              </button>
                
        </div>
        </div>

       
        </div>

    )
}

export default MeassageUser
