import React, { useEffect, useState } from "react";
import { Service } from "../../../agent";
import { Table, Input, Button, Space, Tag, Spin,Row,Col,Modal ,message,Image} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { HiRefresh } from 'react-icons/hi';
import { BiMessageSquareAdd } from 'react-icons/bi';
import axios, { baseURLImg } from "../../../utils/API";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {  RiArrowGoBackFill} from 'react-icons/ri';
import Cookies from "universal-cookie";
import { SideUserPlace } from "./SideUserPlace";
import moment from "moment";


const Managpostuser = () => {

        const cookies = new Cookies();

  const history = useHistory();
  
  const [IdAppPlace, setIdAppPlace] = useState(
    cookies.get("IdAppPlace")
  );

  const GoToMangeCompany = (e) => {
   // localStorage.setItem("IdAppProvince", "");
    history.push("UpdatePlace");
  };

  const FORMAT = "YYYY ddd MMM DD HH:mm";

  const data = [];
  
  const [dataProvince, setdataProvince] = useState([]);

  useEffect(() => {
    try {
 
      
      const getcategoryall = async () => {
        const res = await axios.get("/amal");
        setdataProvince(res.data.data)
      }
getcategoryall()
      
    } catch (error) {
      
    }
    
  }, []);



  if (dataProvince.length < 0) {
    data.push({
      key: "0",
      no: "0",
      UserID: "",
      State: "",
    
      Subject: "",
      createdAt: "",
      likes: "0",
      users: "",
      ImagesPost: "",
      likeslen:0,
    
  })
  }
  else {
     dataProvince.map((res, i) =>
     {
     console.log(res.Image.split('.').pop())
    data.push({
      key: res.IdAmal,
      no: i + 1,
      
      UserID: res.UserID,
      State: res.State,
      DateAdd: moment(res.createdAt).format(FORMAT),
      updatedAt:moment(res.updatedAt).format(FORMAT),
      Subject: res.Title,
      createdAt:res.createdAt,
      ImagesPost:`${baseURLImg}${res.Image}`,
      users: res.users[0].username,
      FolderID:res.FolderID,
      FileID:res.FileID,
      TokenUser:res.TokenUser,
      usersdet:res.users,
      Folder:res.Folder[0].NameFolder,
      File:res.File[0].NameFile
    })
  }
  );
  }
 
  const { confirm } = Modal;
  
  async function showConfirm(e) {

    
    setTimeout(async() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "هل انت متأكد من حذف هذا المنشور ؟ ",
        okText:"نعم",cancelText:"لا",
      async  onOk() {
          axios.delete('/postuser', {
            data: {
              "IdPost": e.key
            }
            
          });
         
          
          setTimeout(async() => {
            const res = await axios.get("/postuser/all/user");
        setdataProvince(res.data.data)
             message.success("Delete");
           
          }, 1000);
     

      // window.location.reload(false);

            
             
           
        },
        onCancel() {
         
        },
         
      });
       
    },1000);
  
      
   
  }

  const UpdateData =async () => {
    const res = await axios.get("/amal");
        setdataProvince(res.data.data);
  };

  const GetAmalFilter =async (e) => {
    const res = await axios.get("/amal");
    const dt = res.data.data
        setdataProvince(dt.filter(res=>res.State===e));
  };

  const [state, setState] = useState({
    searchText: "",
    searchedColumn: "",
  });

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      searchInput,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {}}
          Provinceholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) =>
      state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ searchText: "" });
  };

  const columns = [
  
    {
      title: "اسم المستخدم",
      dataIndex: "users",
      key: "users",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("users"),
      },
     {
      title: "الموضوع",
      dataIndex: "Subject",
      key: "Subject",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("Subject"),
    },
    {
      title: "اسم المجلد",
      dataIndex: "Folder",
      key: "Folder",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("Folder"),
    },
    {
      title: "اسم الملف",
      dataIndex: "File",
      key: "File",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("File"),
    },
      {
      title: "الصورة ",
      dataIndex: "ImagesPost",
      key: "ImagesPost",

      render: (text, record) => (
        <div style={{ display: "flex" }}>
            <Image  style={{ maxHeight: "300px" }} width={"50px"} height={"50px"}
                  src={text.split('.').pop()==="pdf"?window.location.origin + "/img/pdf.jpg":text} />
      
          </div>
      ),
    },
    {
      title: "حالة العمل",
      dataIndex: "State",
      key: "State",
      render: (text) => <div>{text}</div>,
      ...getColumnSearchProps("State"),
      },
      {
        title: "تاريخ العمل",
        dataIndex: "DateAdd",
        key: "DateAdd",
        render: (text) => <div>{text}</div>,
        ...getColumnSearchProps("DateAdd"),
      },
      {
        title: "تاريخ اكمال  العمل",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (text) => <div>{text}</div>,
        ...getColumnSearchProps("updatedAt"),
      },
    {
      title: "Manage",
      dataIndex: "Manage",
      key: "Manage",

      render: (text, record) => (
        <div style={{display:"flex",flexWrap:"wrap"}}>
          <div style={{display:"flex"}}>

          
        <div className="divwsel" onClick={() => UpdateState(record,"مكتمل")}>
          <Tag color="#3FA796">مكتمل</Tag>
        </div>
         <div className="divwsel" onClick={() => UpdateState(record,"غير مكتمل")}>
          <Tag color="#DD4A48">غير مكتمل </Tag>
          </div>
          
          <div className="divwsel" onClick={()=>window.open(record.ImagesPost, "_blank")} >
          <Tag color="#7900FF" style={{color:"#fff"}}>عرض الملف</Tag>
          </div>
          <div className="divwsel" onClick={() =>history.push(`/ManagFilesUser/${record.UserID}/${record.FolderID}`)}>
          <Tag color="#292C6D">مسار الملف</Tag>
          </div>
          </div>
          <div style={{margin:"10px 0 0 0"}} className="divwsel" onClick={() =>
          {
            setIdusernoti(record.UserID)
            setvisibleStatenoti(true)
          }
           
            }>
          <Tag color="#502064">اشعار المستخدم</Tag>
          </div>
          </div>
      ),
    },
  ];
 
    const [Places_Imageupload2, setPlaces_Imageupload2] = useState({ file: "" });

  const [Places_Imageupload3, setPlaces_Imageupload3] = useState({ file: "" });

   const [Places_Image, setPlaces_Image] = useState({ file: "" });
  const [Places_Image1, setPlaces_Image1] = useState({ file: "" });
  const [Places_Image2, setPlaces_Image2] = useState({ file: "" });
  const [Places_Image3, setPlaces_Image3] = useState({ file: "" });

  const [Places_Imageupload, setPlaces_Imageupload] = useState({
   file: window.location.origin + "/img/Noimage.jpg",
  });
    
    const handleChangeImage = (event) => {
    setPlaces_Imageupload({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image({
      file: event.target.files[0],
    });
  };




  const [Places_Imageupload1, setPlaces_Imageupload1] = useState({
   file: window.location.origin + "/img/Noimage.jpg",
  });
    
    const handleChangeImage1 = (event) => {
    setPlaces_Imageupload1({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image1({
      file: event.target.files[0],
    });
  };


    const handleChangeImage2 = (event) => {
    setPlaces_Imageupload2({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image2({
      file: event.target.files[0],
    });
  };

    const handleChangeImage3 = (event) => {
    setPlaces_Imageupload3({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image3({
      file: event.target.files[0],
    });
  };


   //Model Add State

  const [visibleState, setVisibleState] = useState(false);
  const [confirmLoadingState, setConfirmLoadingState] = useState(false);
  const [modalTexStatet, setModalTextState] = useState('Content of the modal');
  const [ModelStateAr, setStateAr] = useState('');
  const [ModelStateArPrice, setStateArPrice] = useState('');
  const [ModelStateArCurncy, setStateArCurncy] = useState('USD');


 
     
  const showModalState = () => {
    setVisibleState(true);
    setStateAr("");
      setStateArPrice("");
      setStateArCurncy("USD");

  };

  const handleOkState = async () => {
      setConfirmLoadingState(true);
    UpdateDetailsPlaceAndUpload3().then(
      () => {
        
    
    setTimeout(async () => {
      
            const res = await axios.get("/postuser/all/user");
        setdataProvince(res.data.data);
      
       setStateAr("");
      setStateArPrice("");
      setStateArCurncy("");
     setVisibleState(false);
      setConfirmLoadingState(false);
      message.success("Add");
    }, 1000);
          }
    )
  };

  const handleCancelState = () => {
    setVisibleState(false);
  };


  //model noti
  const [visibleStatenoti, setvisibleStatenoti] = useState(false);
  const [confirmLoadingState2noti, setconfirmLoadingState2noti] = useState(false);

  const [Idusernoti, setIdusernoti] = useState("");
  const [Titlenoti, setTitlenoti] = useState("");
  const [Subjectnoti, setSubjectnoti] = useState("");

 const handleOkState2noti = async () => {
  setconfirmLoadingState2noti(true);
     
  const url = "/notifiction/sen/alluser/oneuser";
  const formData = new FormData();
  formData.append("document", Places_Image.file);

      formData.append("UserID", Idusernoti);
formData.append("Title", Titlenoti);
  
  formData.append("Subject", Subjectnoti);
 


  const res = await axios.post(url, formData);
      
    if(res.status===200)
    {


    
       const res = await axios.get("/notifiction")
      
      setvisibleStatenoti(false);
      setconfirmLoadingState2noti(false);
    message.success("تم اضافة اشعار للمستخدم بنجاح");
    setIdusernoti("");
    setTitlenoti("");
    setSubjectnoti("");
  
  }
    else {
            const res = await axios.get("/notifiction")
     
            setvisibleStatenoti(false);
            setconfirmLoadingState2noti(false);
     message.success("خطا . لم تتم الاضافة يرجى المحاولة مرة اخرى");
  }

 }

 const handleCancelState2noti = () => {
  setvisibleStatenoti(false);
};

    //Model Update State

  const [visibleState2, setVisibleState2] = useState(false);
  const [confirmLoadingState2, setConfirmLoadingState2] = useState(false);
  const [modalTexStatet2, setModalTextState2] = useState('Content of the modal');
    const [ModelStateAr2, setStateAr2] = useState('');
      const [ModelStateArPrice2, setStateArPrice2] = useState('');
  const [ModelStateArCurncy2, setStateArCurncy2] = useState('USD');
  const [State_ID, setState_ID] = useState('');
  
 const UpdateDetailsPlaceAndUpload2 = (e) => {
    const url = `/category`;
    const formData = new FormData();
    formData.append("document", Places_Image2.file);
    formData.append("document", Places_Image3.file);
   formData.append("Idcategory", State_ID);
   formData.append("name_ar", ModelStateAr2);
   formData.append("name_en", ModelStateArPrice2);
   formData.append("name_ku", "");
    return axios.put(url, formData);
  };


   const UpdateDetailsPlaceAndUpload3 = (e) => {
    const url = `/category`;
    const formData = new FormData();
    formData.append("document", Places_Image.file);
    formData.append("document", Places_Image1.file);
   formData.append("name_ar", ModelStateAr);
   formData.append("name_en", ModelStateArPrice);
   formData.append("name_ku", "");
    return axios.post(url, formData);
  };


  const handleOkState2 = async () => {
        setConfirmLoadingState2(true);
    UpdateDetailsPlaceAndUpload2().then(

      () => {
         
     
        setTimeout(async () => {
      
          const res = await axios.get("/postuser/all/user");
          setdataProvince(res.data.data);
          setStateAr2("");
          setStateArPrice2("");
          setStateArCurncy2("");

          setVisibleState2(false);
          setConfirmLoadingState2(false);
          message.success("Update");
        }, 500);
      }
     )
  
  };

  const handleCancelState2 = () => {
    setVisibleState2(false);
  };

  const UpdateState = async(e,ex) => {
  
    cookies.set("IDpost",e.key)
    
     await axios.put("/amal/stateaml",{"IdAmal":e.key,"State":ex})
    UpdateData()
  };

 

    const titelplace = cookies.get("titelplace")

  return (
     <div style={{ display: "flex", width: "100%" }} >
          <SideUserPlace />
          <div  className="widiv"  style={{ display: "flex",flexDirection:"column"}}>

      {dataProvince.length < 0 && (
        <Spin
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            background: "#ffffffc1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 75454,
          }}
          tip="Loading..."
        ></Spin>
      )}
      <div
        className="dashboard_main"
        style={{ justifyContent: "space-between",background:"rgb(229, 233, 234)",margin:5,padding:"0px 5px" }}
      >
        <div
          style={{ padding: 5, display: "flex", justifyContent: "flex-start" }}
         
        >
          
        </div>

        <div
          style={{ padding: 5, display: "flex", justifyContent: "flex-start" }} className="acton"
        >
          <div 
            onClick={()=>GetAmalFilter("مكتمل")}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="magenta"
            >
             
             الاعمال المكتملة
               <HiRefresh size={20} />
            </Tag>
          </div>

          <div 
            onClick={()=>GetAmalFilter("غير مكتمل")}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="processing"
            >
             
             الاعمال الغير مكتملة
               <HiRefresh size={20} />
            </Tag>
          </div>

          <div 
            onClick={UpdateData}
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag
              style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="orange"
            >
             
              تحديث البيانات
               <HiRefresh size={20} />
            </Tag>
          </div>

          <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Tag className="Tagclass" style={{ padding: 10, fontWeight: 700 }} color="green">
              العدد الكلي : {data.length}
            </Tag>
          </div>

 <div
            style={{
              padding: 5,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >

         
           </div>
          

        

        </div>

         
        
      </div>

    
      <Modal
        title="اضافة اشعار لمستخدم "
        visible={visibleStatenoti}
        onOk={handleOkState2noti}
        confirmLoading={confirmLoadingState2noti}
        onCancel={handleCancelState2noti}
        okText="Add"
            >
             

       <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>العنوان</label>
                  </div>

                  <input
                    className="inputtext"
                    value={Titlenoti}
                    onChange={(e) => setTitlenoti(e.target.value)}
                    required
                   
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الموضوع</label>
                  </div>

                                    
   <textarea
                    style={{ height: 70 }}
                    className="inputtext"
                        value={Subjectnoti}
                    onChange={(e) => setSubjectnoti(e.target.value)}
                    required
                   
                  />
                  
                  
              
              </div>
              

            

             
            </Modal>

                

               
      
       <div style={{ width: "100%",padding:"10px 15px",overflow:'auto' }}>
          <Table
            locale
            bordered
            columns={columns}
            dataSource={data.reverse()}
            pagination={{ pageSize: 5 }}
            key={1}
          />
        </div>
     </div>
    </div>
  );
};

export default Managpostuser;
