import React, { useEffect, useState } from "react";
import { Col, Row, Input, message, Spin ,Button} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import axios from "../../../utils/API";
import { useHistory,useParams } from "react-router-dom";
import Cookies from "universal-cookie";
import "../styledashboardplace.scss";
import "../style.scss"

const Resetpsw = () => {
  // Encrypt
  var CryptoJS = require("crypto-js");
const data = useParams();
const tokenuser =data.token;
const userid =data.userid;
console.log(data)
  const cookies = new Cookies();

  const [EmailUser, setEmailUser] = useState("");
  const [PassUser, setPassUser] = useState("");
  const history = useHistory();

  function getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }
  const isAuthenticated = cookies.get("isAuthenticated");
  
  const [Loading, setLoading] = useState(false);
/*
  useEffect(() => {
    if (isAuthenticated==="true") {
      history.push("DashUserPlace");
      window.location.reload(false);
    } else {
      history.push("LoginPlace");
    }
  }, []);
*/
  

  const LoginUser = async (e) => {
    try {
      e.preventDefault();
     if (PassUser === "") {
        message.info("كلمة المرور مطلوبة");
      } else {
        const tokenuser =data.token;
        const userid =data.userid;
        
        const res = await axios.post(`/users/password-reset/${userid}/${tokenuser}`, {
          password: PassUser,
        });

        console.log(res)

          } 
        
        
      
    } catch (error) {
      message.error(error);
    }
  };

  const [state, setState] = useState({
    loadings: [],
  })
   const enterLoading = (index)=> {

    setState(({ loadings }) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;

      return {
        loadings: newLoadings,
      };
    });
    setTimeout(() => {
      setState(({ loadings }) => {
        const newLoadings = [...loadings];
        newLoadings[index] = false;
        return {
          loadings: newLoadings,
           
        };
      
      });

          

      
    }, 200);
  };

  const { loadings } = state;
  

  return (
    <div>
      {Loading && (
        <Spin
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            height: "100vh",
            width: "100%",
            background: "#ffffffc1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 75454,
          }}
          tip="Loading..."
        ></Spin>
      )}

      <Row style={{ height: "100vh" }}>
        <Col
          style={{
            padding: 15,
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
          xs={24}
          md={24}
          lg={24}
          xl={24}
        >
          <form className="_6luv" onSubmit={(e) => LoginUser(e)}>
            <img
             
              src={window.location.origin + "/img/logorehal.png"}
              alt="Ankidu"
              style={{width:200,height:200,borderRadius:5}}
            />
        
           
            <Input.Password
              value={PassUser}
              onChange={(e) => setPassUser(e.target.value)}
              placeholder="كلمة المرور"
              style={{
                boxShadow: "0 2px 6px 0 #d4cfcf",
                padding: "8px 5px",
                border: "solid rgb(11 173 65)",
                width: "95%",
                borderWidth: "1px 1px 1px 9px",
                fontWeight: "700",
                outline: "none",
                textAlign: "center",
                marginTop: "10px",
              }}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />

            <Button style={{ background: "linear-gradient(45deg,rgb(11 173 65), rgb(32, 159, 216))", height: 40, fontSize: 16, fontWeight: 700 }} className="_55r1 _6lth" type="primary" htmlType="submit" loading={loadings[0]}
            >
          تغير كلمة المرور
            </Button>
            
          
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default Resetpsw;
