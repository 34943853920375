import { Col, Row, Image, message, Tag ,Modal,Checkbox} from "antd";
import React, { useEffect, useState } from "react";
import axios, { baseURLImg, IdAppCompany } from "../../../utils/API.js";
import {Category,Users } from "../../../agent";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';


import { RiChatDeleteFill ,RiArrowGoBackFill, RiRoadsterFill, RiSuperscript} from 'react-icons/ri';
import { AiTwotoneSave} from 'react-icons/ai';
import Cookies from "universal-cookie";
import { SideUserPlace } from "./SideUserPlace.js";
import moment from "moment";
const FORMAT = "MM-DD-YYYY  HH:mm";


const UserAddToMyPlace = () => {

         const cookies = new Cookies();

    const CatPlace = cookies.get("usercat");

  const [IdUser, setIdUser] = useState(cookies.get("userid"));
    const [iduserupdate, setiduserupdate] = useState(cookies.get("iduserupdate"));

    const [IdAppPlace, setIdAppPlace] = useState(
    cookies.get("IdAppPlace")
  );

  function destroyAll() {
  Modal.destroyAll();
}


  const { confirm } = Modal;
  
  function showConfirm() {
    setTimeout(() => {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "هل انت متأكد من حذف هذا المستخدم ؟ ",
        okText:"نعم",cancelText:"لا",
        onOk() {
        axios.delete('/users/', {data:{"id":iduserupdate}});
          message.success("Delete");
          cookies.set("IdAppPlace", IdAppPlace);
          setTimeout(() => {
          history.push("MangeUserMyPlace");

          }, 500);
        },
        onCancel() {
         
        },
      });
    },500);
  
  }
  
const history = useHistory();

  
    const [CategoryCompany, setCategoryCompany] = useState([]);
    const [StateCompany, setStateCompany] = useState([]);

    const GoToMangePlaces = (e) => {
    history.push("MangeUserMyPlace");
  };

  



  const [Places_Image, setPlaces_Image] = useState({ file: "" });

  const [PhotoUser, setPhotoUser] = useState({
   file: window.location.origin + "/img/Noimage.jpg",
  });

const [datasupuser, setdatasupuser] = useState([]);

  useEffect(() => {
    try {
      const getallsupuser = async () => {
        const res = await axios.get("/supscriptadsuser")
        setdatasupuser(res.data.data)
      }
      getallsupuser();
    } catch (error) {
      
    }
    
  }, []);

  const [username, setusername] = useState("");
  const [iduser, setiduser] = useState("");
  const [asemmshro, setasemmshro] = useState("");
  const [Passowrd, setPassowrd] = useState("");
  const [Address, setAddress] = useState("");
  const [typeradsres, settyperadsres] = useState("");
  const [typeradsres2, settyperadsres2] = useState("مجاني");
const [rqmadbara, setrqmadbara] = useState("");
const [createdAt, setcreatedAt] = useState("");

const [rqmadbara2, setrqmadbara2] = useState("");
const [Idsupscripuserads, setIdsupscripuserads] = useState("");


  const [Role, setRole] = useState("User");
  const [Phone, setPhone] = useState("");
  const [TypeUser, setTypeUser] = useState("");
  const [state, setstate] = useState("Active");
  const [UserPermissions, setUserPermissions] = useState({
                "read": "true",
                "add": "true",
                "update": "true",
                "delete": "true"
            });
  const [Places_Imageupload, setPlaces_Imageupload] = useState({
    file: window.location.origin + "/img/Noimage.jpg",
  });
  const UpdateDetailsPlaceAndUpload = (file) => {
    const url = "/users";
    const formData = new FormData();


        formData.append("iduser", iduser);

    
    formData.append("username", username);
    formData.append("asemmshro", asemmshro);
    formData.append("phone", Phone);
    formData.append("address", Address);

    formData.append("role", Role);
    formData.append("rqmadbara", rqmadbara);
      formData.append("state", state);
   
      
    return axios.post(url, { username, iduser,phone:Phone,address:Address,role:Role,rqmadbara,asemmshro,password:Passowrd });
  };

  const UpdateRestPass =async () => {
   
    await axios.put("/users/restpwsuserdash",{"Passowrd":Passowrd,"id":iduserupdate});
  };



  
  const handleChangeImage = (event) => {
    setPhotoUser({
      file: URL.createObjectURL(event.target.files[0]),
    });

    setPlaces_Image({
      file: event.target.files[0],
    });
  };

  const UpdateDetailsPlace = async (e) => {
    try {
    
      e.preventDefault();

        if (resetpsw===true) {
            UpdateDetailsPlaceAndUpload().then(
        (response) => {console.log(response)}
          );
          setTimeout(() => {
            UpdateRestPass().then(
        (response) => {console.log(response)}
          );
          }, 500);
         
      message.success("Save");
        }
        else {
              UpdateDetailsPlaceAndUpload().then(
        (response) => {console.log(response)}
      );
      message.success("Save");
        }
  
    
    } catch (error) {
      console.log(error)
    }
    
  };


//  const [UserPermissions, setUserPermissions] = useState([{read:true,update:true,delete:true,add:true}]);

    //check box auth
    const OnchangeRead = (e) => {
        setUserPermissions({...UserPermissions, read: e })
    }

    const OnchangeDealte = (e) => {
        setUserPermissions({...UserPermissions, delete: e })
    }

    const OnchangeUpdate = (e) => {
        setUserPermissions({...UserPermissions, update: e })
    }

    const OnchangeAdd = (e) => {
        setUserPermissions({...UserPermissions, add: e })
  }
  
  //password reset

  const [resetpsw, setresetpsw] = useState(false)
  const [resetpswText, setresetpswText] = useState("Password Reset")
  const RestPsw =async () => {
    if (resetpsw==false) {
      setresetpsw(true);
    setPassowrd("");
    setresetpswText('Cancel')
    }
    else {
      setresetpsw(false);
      const dataPlace = await Users.GetOneUserByIdUser(iduserupdate);
      setPassowrd(dataPlace.data.data[0].Passowrd);
      setresetpswText('Password Reset')
    }
  

  }


  return (
        <div style={{ display: "flex", width: "100%" }}>
                    <SideUserPlace />
      <div  className="widiv"  style={{ display: "flex",flexDirection:"column" }}>
      <form onSubmit={(e) => UpdateDetailsPlace(e)}>
        
        <div className="dashboard_main" style={{ justifyContent: "center"}}>
          <Row>

              <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",justifyContent:"space-between",background:"rgb(229 233 234)"
              }}
              xs={24}
              md={24}
              lg={24}
              xl={24}
            >
              <button   style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }} className="btnsavecompany" type="submit">
              أضـافة
                <AiTwotoneSave size={20} />
              </button>

              <div className="acton"  style={{
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",justifyContent:"space-between"
              }}>
               
                 
                  

              <Tag
               onClick={GoToMangePlaces}
               style={{ padding: 10, fontWeight: 700,display:"flex",justifyContent:"space-between",alignItems:"center" }}
              className="Tagclass"
              color="green"
            >
                 الرجوع الى اعدادت المستخدمين
                   <RiArrowGoBackFill size={20} style={{margin:"0 5px 0 0"}}/>
                </Tag>
                </div>
            </Col>
            <Col
              style={{
                padding: 5,
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
              }}
              xs={24}
              md={24}
              lg={18}
              xl={20}
            >
               <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>رقم المستخدم</label>
                  </div>

                  <input
                  
                    className="inputtext"
                    type="text"
                    value={iduser}
                    onChange={(e) => setiduser(e.target.value)}
                    required
                    placeholder="Please Enter iduser"
                  />
                </div>
              </Col>

              <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>الاسم المستخدم</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={username}
                    onChange={(e) => setusername(e.target.value)}
                    required
                    placeholder="ُEnter username"
                  />
                </div>
              </Col>

             

              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>عنوان المشروع</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={asemmshro}
                    onChange={(e) => setasemmshro(e.target.value)}
                    required
                    placeholder="Please Enter asemmshro"
                  />
                </div>
              </Col>

              <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>رقم الهاتف</label>
                  </div>

                  <input
                    
                    className="inputtext"
                    type="number"
                    value={Phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                      placeholder="Please Enter Phone"
                      maxLength="11"
                  />
                </div>
              </Col>

              




              <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>كلمة المرور</label>
                  </div>

                  <input
                    className="inputtext"
                    type="password"
                    value={Passowrd}
                    onChange={(e) => setPassowrd(e.target.value)}
                    required
                    placeholder="Please Enter Password"
                  />
                </div>
              </Col>

                          <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>العنوان</label>
                  </div>

                  <input
                    className="inputtext"
                    type="text"
                    value={Address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    placeholder="Please Enter Address"
                  />
                </div>
                          </Col>
                          
                            <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>رقم الاضبارة</label>
                  </div>

                    <input
                      
                    className="inputtext"
                    type="text"
                  
                    value={rqmadbara}
                    onChange={(e) => setrqmadbara(e.target.value)}
                    required
                    placeholder="Please Enter typeradsres"
                    />

                   
                   
                </div>
                </Col>
                
                          <Col
                style={{
                  padding: 10,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>صلاحية المستخدم</label>
                  </div>

                   <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                    value={Role}
                    onChange={(e) => setRole(e.target.value)}
                    required
                    placeholder="Please Enter Role User"
                  >
                    <option>Admin</option>
                    <option>User</option>
                                  </select>

                 
                </div>
              </Col>
             

                <Col
                style={{
                  padding: 5,
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                xs={24}
                md={8}
                lg={8}
                xl={8}
              >
                <div style={{ width: "100%" }}>
                  <div className="lableinput">
                    <label>حالة المستخدم</label>
                  </div>
                  <select
                    style={{ padding: 6 }}
                    className="inputtext"
                    type="text"
                    value={state}
                    onChange={(e) => setstate(e.target.value)}
                    required
                    placeholder="Please Enter state"
                  >
                    <option>Active</option>
                    <option>InActive</option>
                  </select>
                </div>
                          </Col>

       
          

                         

</Col>
          
         
          
          </Row>
        </div>
      </form>
      </div>
       </div>
  );
};

export default UserAddToMyPlace;
